// ----------------------------------------------
// Imports
// ----------------------------------------------
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_animation';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_colors';

.hero {
  flex-direction: column;

  @media #{$medium-up} {
    padding: 3em;
  }
}

.textArea {
  font-size: 2rem;
  font-weight: 400;
  order: 2;
  padding-top: 1em;
  padding-bottom: 1em;
  text-align: center;

  @media #{$medium-up} {
    font-size: 2.75rem;
    order: 1;
    padding-bottom: 1em;
  }
}

.text {
  font-size: 2rem;
  margin: 0;

  @media #{$medium-up} {
    font-size: 2.75rem;
  }
}

.holder {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sizer {
  max-width: 900px;
  width: 100%;
  z-index: 2;
}
